import { graphql } from "gatsby"
import React from "react"
import { Layout } from "@bw/layouts"
import { Seo, Button } from "@bw/bits"
import * as modules from "@bw/modules/resolvers"

const PageTemplate = ({ data, pageContext }) => {
  const page = data.collectionPages

  if (typeof page === "undefined") {
    return null
  }

  let shouldDisplayHeader = true

  if (page.content[0].__typename === "ArticlesModule") {
    shouldDisplayHeader = false
  }

  return (
    <Layout {...{ pageContext }}>
      <Seo
        title={page.metaTitle || page.title}
        description={page.metaDescription}
        meta={[
          {
            name: "robots",
            content: page.doNotIndex
              ? "noindex, nofollow"
              : "max-image-preview:large, index, follow",
          },
        ]}
      />
      {shouldDisplayHeader &&
        page.header
          .filter(module => module !== null)
          .map((module, i) => {
            const LoadedModule =
              modules[module.__typename.replace("Module", "")]
            if (typeof LoadedModule === "undefined") {
              return null
            }

            return <LoadedModule key={i} {...module} />
          })}
      {page.content
        .filter(module => module !== null)
        .map((module, i) => {
          const LoadedModule = modules[module.__typename.replace("Module", "")]
          if (typeof LoadedModule === "undefined") {
            return null
          }

          if (module.buttonType?.value === "url" && module.buttonUrl) {
            module.button = (
              <Button
                primary
                label={module.buttonText}
                href={module.buttonUrl}
                external={module.buttonIsExternal}
              />
            )
          }

          if (module.buttonType?.value === "entry" && module.buttonEntry) {
            module.button = (
              <Button
                primary
                label={module.buttonText}
                to={module.buttonEntry.url}
              />
            )
          }
          /**
           * article page has a different layout
           */
          if (i === 0 && module.__typename === "ArticlesModule") {
            return <LoadedModule key={i} {...module} {...page.header[0]} />
          }

          return <LoadedModule key={i} {...module} />
        })}
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query pageQuery($id: String!) {
    collectionPages(id: { eq: $id }) {
      title
      metaTitle
      metaDescription
      image {
        url
      }
      doNotIndex

      teaserImage {
        url
      }
      header {
        __typename
        suptitle
        title
        subtitle

        ... on HeroModule {
          ...HeroModuleFragment
        }
      }
      content {
        __typename
        suptitle
        title
        subtitle
        centerTitle
        backgroundVariant
        buttonType {
          value
        }
        buttonUrl
        buttonIsExternal
        buttonText
        buttonPosition {
          value
        }
        buttonEntry {
          url
        }

        ... on CallToActionModule {
          ...CallToActionModuleFragment
        }
        ... on ContentModule {
          ...ContentModuleFragment
        }
        ... on ContentWithImageModule {
          ...ContentWithImageModuleFragment
        }
        ... on ContentWithImageWithMultipleButtonsModule {
          ...ContentWithImageWithMultipleButtonsModuleFragment
        }
        ... on GalleryModule {
          ...GalleryModuleFragment
        }
        ... on LubricantsModule {
          ...LubricantsModuleFragment
        }
        ... on ContentOnColumnsModule {
          ...ContentOnColumnsModuleFragment
        }
        ... on TeasersLatestModule {
          ...TeasersLatestModuleFragment
        }
        ... on TeasersCustomModule {
          ...TeasersCustomModuleFragment
        }
        ... on TeasersManualModule {
          ...TeasersManualModuleFragment
        }
        ... on LogoGridModule {
          ...LogoGridModuleFragment
        }
        ... on ImageModule {
          ...ImageModuleFragment
        }
        ... on TimelineModule {
          ...TimelineModuleFragment
        }
        ... on TestimonialModule {
          ...TestimonialModuleFragment
        }
        ... on StationAndAppModule {
          ...StationAndAppModuleFragment
        }
        ... on VideoModule {
          ...VideoModuleFragment
        }
        ... on CardsModule {
          ...CardsModuleFragment
        }
        ... on ComparatorModule {
          ...ComparatorModuleFragment
        }
        ... on FormModule {
          ...FormModuleFragment
        }
        ... on FaqModule {
          ...FaqModuleFragment
        }
        ... on DownloadsModule {
          ...DownloadsModuleFragment
        }
        ... on ContentWithSideButtonModule {
          ...ContentWithSideButtonModuleFragment
        }
        ...CantonsMapModuleFragment
      }
    }
  }
`
