import React, { useMemo } from "react"
import { graphql } from "gatsby"
import { useLocalization } from "gatsby-theme-i18n"
import { Gallery as PhotoSwipe, Item } from "react-photoswipe-gallery"
import * as modules from "@bw/modules/resolvers"
import styled from "styled-components"
import { breakpoints } from "../theme"
import { useTranslation } from "react-i18next"
import { Layout } from "@bw/layouts"
import { Seo, Button, Spacer, Container, Grid, CmsImage } from "@bw/bits"
import useCollectionArticles from "../hooks/useCollectionArticles"

const ArticleTemplate = ({ data, pageContext }) => {
  const { localizedPath, defaultLang, locale } = useLocalization()

  const allArticles = useCollectionArticles()
  const page = data.collectionArticles
  const { prev, next } = useMemo(() => {
    const currentIndex = allArticles.findIndex(a => a.id === page.id)

    return {
      prev: currentIndex === 0 ? null : allArticles[currentIndex - 1]?.to,
      next:
        currentIndex === allArticles.length - 1
          ? null
          : allArticles[currentIndex + 1]?.to,
    }
  }, [allArticles, page.id])
  const { t } = useTranslation()

  return (
    <Layout {...{ pageContext }}>
      <Seo
        title={page.metaTitle || page.title}
        description={page.metaDescription}
        meta={[
          {
            name: "robots",
            content: page.doNotIndex
              ? "noindex, nofollow"
              : "max-image-preview:large, index, follow",
          },
        ]}
      />
      <Container size="large">
        <Wrapper>
          <Grid gap="small">
            <div className="content">
              <p>
                {new Date(page.date).toLocaleDateString(pageContext.locale, {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </p>
              <h1 style={{ marginBottom: "-60px" }}>{page.title}</h1>
              {page.content
                .filter(module => module !== null)
                .map((module, i) => {
                  const LoadedModule =
                    modules[module.__typename.replace("Module", "")]
                  if (typeof LoadedModule === "undefined") {
                    return null
                  }

                  if (module.buttonType.value === "url" && module.buttonUrl) {
                    module.button = (
                      <Button
                        primary
                        label={module.buttonText}
                        href={module.buttonUrl}
                        external={module.buttonIsExternal}
                      />
                    )
                  }

                  if (
                    module.buttonType.value === "entry" &&
                    module.buttonEntry
                  ) {
                    module.button = (
                      <Button
                        primary
                        label={module.buttonText}
                        to={module.buttonEntry.url}
                      />
                    )
                  }

                  return <LoadedModule key={i} {...module} />
                })}
              <Spacer direction="row">
                <Button
                  label={t("back")}
                  to={
                    prev ||
                    localizedPath({
                      defaultLang,
                      locale,
                      path: "/",
                    })
                  }
                  primary
                />
                {next && <Button label={t("next")} to={next} primary />}
              </Spacer>
            </div>
            <div className="assetsGrid">
              <PhotoSwipe
                shareButton={false}
                fullscreenButton={false}
                options={{
                  hideAnimationDuration: 0,
                  showAnimationDuration: 0,
                }}
              >
                <AssetsGrid>
                  {page.assets.map((asset, i) => (
                    <Item
                      key={i}
                      original={asset.url}
                      width={asset.width}
                      height={asset.height}
                    >
                      {({ ref, open }) => (
                        <div ref={ref} onClick={open} role="button">
                          <CmsImage src={asset} alt="" />
                        </div>
                      )}
                    </Item>
                  ))}
                </AssetsGrid>
              </PhotoSwipe>
            </div>
          </Grid>
        </Wrapper>
      </Container>
    </Layout>
  )
}

const AssetsGrid = styled.div`
  display: grid;
  grid-gap: 36px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fill, 120px);

  @media (min-width: ${breakpoints.large}px) {
    grid-template-rows: repeat(auto-fill, 220px);
  }

  > :first-child {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }

  > * {
    cursor: pointer;
  }
`

const Wrapper = styled.div`
  padding: 50px 0 0;

  @media (min-width: ${breakpoints.small}px) {
    padding: 75px 0 0;
  }

  h1 {
    margin: 0;
    font-size: 20px;
    max-width: 440px;
  }

  .container {
    padding: 0;
  }

  .content {
    max-width: 534px;
  }

  .assetsGrid {
    @media (min-width: ${breakpoints.small}px) {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }
  }

  .gatsby-image-wrapper {
    width: 100%;
  }
`

export default ArticleTemplate

export const articleQuery = graphql`
  query articleQuery($id: String!) {
    collectionArticles(id: { eq: $id }) {
      id
      title
      metaTitle
      metaDescription
      image {
        url
      }
      date
      doNotIndex
      teaserImage {
        url
      }
      assets {
        url
        width
        height
        localImage {
          childImageSharp {
            gatsbyImageData(width: 600)
          }
        }
      }
      content {
        __typename
        suptitle
        title
        subtitle
        centerTitle
        backgroundVariant
        buttonType {
          value
        }
        buttonUrl
        buttonIsExternal
        buttonText
        buttonPosition {
          value
        }
        buttonEntry {
          url
        }

        ... on CallToActionModule {
          ...CallToActionModuleFragment
        }
        ... on ContentModule {
          ...ContentModuleFragment
        }
        ... on ContentWithImageModule {
          ...ContentWithImageModuleFragment
        }
        ... on ContentWithImageWithMultipleButtonsModule {
          ...ContentWithImageWithMultipleButtonsModuleFragment
        }
        ... on GalleryModule {
          ...GalleryModuleFragment
        }
        ... on ContentOnColumnsModule {
          ...ContentOnColumnsModuleFragment
        }
        ... on TeasersLatestModule {
          ...TeasersLatestModuleFragment
        }
        ... on LogoGridModule {
          ...LogoGridModuleFragment
        }
        ... on ImageModule {
          ...ImageModuleFragment
        }
        ... on TestimonialModule {
          ...TestimonialModuleFragment
        }
        ... on VideoModule {
          ...VideoModuleFragment
        }
        ... on DownloadsModule {
          ...DownloadsModuleFragment
        }
      }
    }
  }
`
